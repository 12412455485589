import * as React from 'react';
import {
  ArticleTemplateQuery,
  InsideEdgeTemplateQuery,
  PageTemplateQuery,
  PortfolioTemplateQuery,
  ContentfulTopicPortfolio,
  ContentfulTopicPortfolioSeries,
  BioTemplateQuery,
} from '../../graphql-types';
import { PageContext } from '../types/pageContext';

type TemplateQueries =
  | ArticleTemplateQuery
  | InsideEdgeTemplateQuery
  | PageTemplateQuery
  | PortfolioTemplateQuery
  | BioTemplateQuery;

export interface IGlobalState {
  site?: string;
  performanceApprovalDate?: string;
  pageContext?: PageContext;
  pageData?: TemplateQueries;
  location?: Location;
  portfolio?: ContentfulTopicPortfolio;
  allPortfolioSeries?: ContentfulTopicPortfolioSeries[];
}
export const GlobalStateContext = React.createContext<IGlobalState>({
  site: process.env.GATSBY_GLOBAL_WEBSITE,
  performanceApprovalDate: null,
  pageContext: null,
  pageData: null,
  location: null,
  portfolio: null,
  allPortfolioSeries: [],
});

/**
 * Global State hook for tracking global properties that can be used in
 * multiple components throughout the site. Usage:
 *
 * const { site, pageContext, pageData, location } = useGlobalState();
 * @returns IGlobalState
 */
export const useGlobalState = (): IGlobalState => {
  const state = React.useContext(GlobalStateContext);
  return state;
};
