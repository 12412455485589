import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en-CA';
import '@formatjs/intl-numberformat/locale-data/fr-CA';
import '@formatjs/intl-numberformat/locale-data/zh-Hans';
import './src/styles/global.css';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { WEBSITE } from './src/types/website.enum';
import { GlobalStateContext, IGlobalState } from './src/hooks/useGlobalState';
import { ContentfulTopicPortfolioSeries } from './graphql-types';
import { Auth, Amplify } from 'aws-amplify';

declare global {
  interface Window {
    dataLayer: any;
  }
}

// attach print handler if gtm has loaded
if (window.dataLayer) {
  window.addEventListener('beforeprint', () => {
    const pageTitle = document.title;
    window.dataLayer?.push({
      event: `printPage`,
      value: `Page Title: ${pageTitle}`,
    });
  });
}

if (process.env.GATSBY_GLOBAL_WEBSITE === WEBSITE.CYMBRIA) {
  Amplify.configure({
    Auth: {
      region: process.env.GATSBY_COGNITO_REGION,
      userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
    },
  });
  Auth.configure();
}

export const wrapPageElement = ({ element, props }) => {
  // setup global state
  // this allows for deeply nested components to have access to page-level
  // data and context without have to prop-drill through intermediate components
  const globalState: IGlobalState = {
    site: process.env.GATSBY_GLOBAL_WEBSITE,
    pageContext: props?.pageContext,
    pageData: props?.data,
    location: props?.location,
    portfolio: props?.data?.contentfulComposePage?.content,
    performanceApprovalDate:
      props?.data?.contentfulComposePage?.website?.performanceApprovalDate,
    allPortfolioSeries:
      props?.data?.allContentfulTopicPortfolioSeries?.edges?.map(
        (edge) => edge.node,
      ) as ContentfulTopicPortfolioSeries[],
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
    >
      <GlobalStateContext.Provider value={globalState}>
        {element}
      </GlobalStateContext.Provider>
    </GoogleReCaptchaProvider>
  );
};
